import { GAME_EVENT_TYPES } from '~/constants/game';
import { REDIRECTION_ROUTES_QUERIES } from '~/constants/redirectionRoutes';
import { CASH_TYPES } from '~/constants/cash';

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) {
    return;
  }
  const eventType = to.params.eventType as string;

  const cashierHandler = () => {

    if (window.top && window.self !== window.top) {
      window.top.location.href = `${window.top.location.href}?${REDIRECTION_ROUTES_QUERIES.cashier}=${CASH_TYPES[0]}`;
    } else {
      const redirectionPath = from.fullPath === to.fullPath
        ? '/'
        : from.fullPath;

      return navigateTo(
        useLocalePrefixForHref(`${redirectionPath}?${REDIRECTION_ROUTES_QUERIES.cashier}=${CASH_TYPES[0]}`),
      );
    }
  };

  const exitHandler = () => {
    if (window.top && window.self !== window.top) {
      window.top.location.href = '/';
    } else {
      return navigateTo(useLocalePrefixForHref('/'));
    }
  };

  switch(eventType) {
    case GAME_EVENT_TYPES.cashier:
      return cashierHandler();
    case GAME_EVENT_TYPES.exit:
      return exitHandler();
  }

  if (!Object.values(GAME_EVENT_TYPES).includes(eventType)) {
    throw createError({
      statusCode: 404,
      message: 'Page not found',
      fatal: true,
    });
  }
});
