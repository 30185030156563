export const BLOCKED_COUNTRIES = {
  in: [
    'BR',
    'AU',
    'BG',
    'CN',
    'CY',
    'EE',
    'ES',
    'FR',
    'GB',
    'KP',
    'NL',
    'HU',
    'IO',
    'IN',
    'IR',
    'IT',
    'LT',
    'LV',
    'ME',
    'MT',
    'RO',
    'SE',
    'TR',
    'UA',
    'US',
    'VE',
    'PT',
    'GR',
    'FI',
    'CA',
  ],
  out: [
    'BG',
    'CN',
    'CY',
    'EE',
    'ES',
    'FR',
    'GB',
    'KP',
    'NL',
    'HU',
    'IO',
    'IR',
    'IT',
    'LT',
    'LV',
    'ME',
    'MT',
    'RO',
    'SE',
    'TR',
    'UA',
    'US',
    'VE',
    'PT',
  ],
};

export const CASH_TYPES = ['in', 'out'];

export const CASH_TABS = {
  deposit: 'cashier_in',
  withdrawal: 'cashier_out',
};